.side-menu {
    background: #3E75A8 !important;
}

.time-sheet-header {
    background: #FFFFFF;
    border-radius: 0 26.5px 0 26.5px;
    padding: 16px;
    margin: 16px;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 26px;
    text-align: center;
    color: #3E75A8;
    letter-spacing: 0;
    white-space: pre-line;
}

.menu-link {
    color: #fff !important;
    padding-left: 32px !important;
    margin-top: 12px !important;
}

.menu-link svg {
    color: #fff !important;
}

.profile-pic {
    border-radius: 20px !important;
    width: 50px !important;
    height: 50px !important;
}

.custom-side-menu.menu-closed a>div:nth-child(1) {
    min-width: 72px;
}

.logout-btn {
    min-width: 42px !important;
}

.selected-menu {
    background: #FFFFFF !important;
    border-radius: 25px 0 0 25px !important;
    border-right: 4px solid #EDBF3A !important;
    color: #274969 !important;
}

.selected-menu svg {
    color: #274969 !important;
}

.side-menu-container {
    height: 100vh;
}

.side-menu-container>div:nth-child(1) {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-width:599px) {
    .time-sheet-header {
        padding: 8px
    }
    .hide-xs {
        display: none;
    }
}