.not-auth-bg {
    background-image: url('/images/bg_image.png') !important;
    background-repeat: no-repeat;
    background-size: cover !important;
    padding-top: 84px !important;
    padding-bottom: 84px !important;
}

.not-auth-bg .card {
    background: #FFFFFF;
    box-shadow: 0 0 5px 1px rgba(198, 198, 198, 0.50) !important;
}

.not-auth-bg .secondary-action-text {
    color: #398CDB !important;
}

@media screen and (min-width:1200px) {
    .not-auth-bg .card {
        padding: 72px !important;
    }
}