body {
  color: #274969 !important;
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #515c6608 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/******************Loading ***********/

.loading-dialog>div>div {
  background-color: transparent;
  box-shadow: none;
}

.loading {
  color: #fff;
  padding-top: 8px;
}

/*******************custom******************/

.select-label {
  background-color: #fff;
  padding: 0 5px !important;
}

.custom-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-react-select-form {
  position: relative;
}

.custom-react-select-label {
  position: absolute;
  margin-bottom: 3px !important;
  z-index: 10 !important;
  padding: 0 2px !important;
  background-color: #fff !important;
  margin-top: -6px !important;
  margin-left: 12px !important;
  font-size: 12px !important;
}

.title {
  font-size: 24px !important;
  letter-spacing: 0 !important;
  font-weight: 600 !important;
}

.custom-title {
  color: grey;
}

.custom-fields {
  border-radius: 6px;
}

.anchor-color {
  color: #398cdb !important;
  text-decoration: none;
}

.url-link {
  height: 64px;
}

.word-wrap-after {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-all;
  /* white-space: nowrap; */
  /* line-height: 16px; */
  max-height: 40px;
  /* The number of lines to be displayed */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.word-wrap {
  word-break: break-all;
  /* height: 40px; */
}

.timesheet-table thead {
  background: #3e75a8 !important;
  color: #fff !important;
}

.timesheet-table thead th {
  color: #fff !important;
}

.timesheet-table tbody tr:nth-child(2n) {
  background: #f9fafa;
}

.timesheet-table tbody tr:nth-child(2n + 1) {
  background: #f2f6f8;
}

.timesheet-table tbody td {
  color: #515c66 !important;
}

/*************************sidebar*********************/

.selected-item {
  background-color: rgba(0, 0, 0, 0.08);
}

.react-select>div:nth-child(2) {
  /* background: #fff !important; */
  z-index: 9;
}

.react-select>div:nth-child(1) {
  padding: 1px;
  background: transparent;
}

.react-select>div:nth-child(3) {
  /* background: #fff !important; */
  z-index: 11;
}

.logo-image {
  width: 100%;
  height: 75px !important;
  object-fit: contain !important;
}

.logo-image-view {
  position: relative;
  width: 150px;
  padding: 4px;
  border: 1px solid #ddd;
}

.logo-image-view>span {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

/****************************************** primitive helper**************************************/

.pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.btn {
  min-height: 50px !important;
  border-radius: 25px !important;
  min-width: 100px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.tabs-container>div>span {
  display: none;
  /*  remove bottom color  */
}

.add-btn {
  border-radius: 25px !important;
  /* min-width: 170px !important; */
  box-shadow: none !important;
  min-height: 50px !important;
  color: #46484a !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.cancel-btn, .import-btn {
  font-size: 16px !important;
  color: #46484a !important;
  letter-spacing: 0 !important;
  border: none !important;
  min-height: 50px !important;
}

.search-btn, .reset-btn, .close-btn {
  background: none !important;
  box-shadow: none !important;
  color: #3e75a8 !important;
  border: none !important;
  border-left: 1px solid rgba(95, 98, 102, 0.2) !important;
  border-radius: 0 !important;
}

.close-btn {
  color: #5f6266 !important;
}

.drawer-title {
  font-size: 22px !important;
  color: #274969 !important;
  letter-spacing: 0 !important;
}

.card {
  background: #ffffff !important;
  box-shadow: 0 0 5px 1px rgba(198, 198, 198, 0.5) !important;
  border-radius: 8px !important;
  border-radius: 8px !important;
  padding: 16px !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-16 {
  padding: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.m-0 {
  margin: 0;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-8i {
  margin-top: 8px !important;
}

.m-16 {
  margin: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mt-16i {
  margin-top: 16px !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.red {
  color: red;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #324d66;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fw-regular {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.d-flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-column {
  flex-direction: column;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-center {
  align-items: center;
}

.capitalize {
  text-transform: capitalize;
}

.max-width-900 {
  max-width: 900px;
}

/***********other ***************/

.report {
  display: flex;
  flex-flow: wrap;
}

.crop-tool-container {
  margin: 0 !important;
  width: 100% !important;
}

/******************************media query************************/

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
  .small>div:nth-child(3) {
    width: 100% !important;
  }
  .medium>div:nth-child(3) {
    width: 100% !important;
  }
  .large>div:nth-child(3) {
    width: 100% !important;
  }
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767.98px) {
  .small>div:nth-child(3) {
    width: 85% !important;
  }
  .medium>div:nth-child(3) {
    width: 90% !important;
  }
  .large>div:nth-child(3) {
    width: 100% !important;
  }
}

/* Medium devices (tablets, less than 992px) */

@media (min-width: 768px) and (max-width: 991.98px) {
  .small>div:nth-child(3) {
    width: 65% !important;
  }
  .medium>div:nth-child(3) {
    width: 75% !important;
  }
  .large>div:nth-child(3) {
    width: 85% !important;
  }
}

/* Large devices (desktops, less than 1200px) */

@media (min-width: 992px) and (max-width: 1199.98px) {
  .small>div:nth-child(3) {
    width: 55% !important;
  }
  .medium>div:nth-child(3) {
    width: 65% !important;
  }
  .large>div:nth-child(3) {
    width: 75% !important;
  }
}

/*  Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .small>div:nth-child(3) {
    width: 45% !important;
  }
  .medium>div:nth-child(3) {
    width: 55% !important;
  }
  .large>div:nth-child(3) {
    width: 75% !important;
  }
}