.company-card {
    background: #FFFFFF !important;
    box-shadow: 0 0 5px 1px rgba(198, 198, 198, 0.50) !important;
    border-radius: 16px !important;
}

.company-card img {
    width: 120px;
}

.company-card .company-details {
    padding-left: 30px !important;
}

.company-name {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #324D66;
}

.company-card .email {
    font-weight: 500 !important;
    color: #324D66;
    font-size: 16px;
}

.tab-menu {
    font-size: 20px !important;
    text-transform: initial !important;
}

.tab-selected {
    border-bottom: 4px solid #324D66 !important;
    font-weight: 600 !important;
}

.overflow-auto {
    overflow: auto;
}

@media screen and (max-width:599px) {
    .company-card .company-details {
        padding-left: 0px !important;
    }
}